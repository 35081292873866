import React, { useState } from "react"
import { motion } from "framer-motion"

import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import styled, { css } from "styled-components"
import * as v from "../../components/variables"
import SEO from "../../components/seo"
import Header from "../../components/Header"

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  ${v.grid};
  grid-template-rows: [header-start] ${v.baseline(6.5)} [header-end content-start] auto [content-end footer-start] ${v.baseline(
      6
    )} [footer-end];

  grid-row-gap: 0 !important;
`

const GuideHeader = styled.header`
  grid-column: main;
  grid-row: header;
  padding: ${v.baseline(2)} 0;

  a {
    text-decoration: none;
  }
  .back {
    ${v.heading.b};
    padding-right: ${v.baseline(0.5)};
    line-height: ${v.baseline(2)};
    float: none;
  }
  h1 {
    ${v.heading.c}
    font-weight: bold;
    color: ${v.purple};
    span {
      font-weight: 300;
      float: right;
    }
  }
  border-bottom: 1px solid rgb(0 0 0 / 0.1);
`

const GuideSteps = styled.ol`
  grid-column: fullbleed;
  grid-row: content;
  ${v.grid}
  counter-reset: steps-counter;
`
const GuideStep = styled.li`
  grid-column: main;
  counter-increment: steps-counter;
  ${v.text.a};
  color: rgb(0 0 0 / 0.6);
  height: ${`calc(100vh - ` + v.baseline(17) + `)`};
  padding-top: ${v.baseline(2)};

  &:before {
    content: ${props => "'" + props.number + "'"};
    ${v.heading.c};
    display: flex;
    width: ${v.baseline(4)};

    border-radius: 100px;
    background: ${v.purple};
    color: white;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: ${v.baseline()};
    padding: 10px 0 8px;
    @media all and (min-width: 768px) {
      margin-left: -${v.baseline(6)};
    }
  }

  img,
  video {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    margin-top: ${v.baseline()};
    object-fit: contain;
    object-position: center;
  }

  > div {
    display: flex;
    min-height: ${v.baseline(4)};
    align-items: center;
  }
`

const NextPrevious = styled.ul`
  border-top: 1px solid rgba(0 0 0 / 0.1);
  grid-column: footer;
  background: white;
  padding: ${v.baseline()};
  grid-column: fullbleed;
  grid-row: footer;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media all and (min-width: 768px) {
    grid-column: main;
  }

  button:before,
  button:after {
    font-weight: 300;
    font-size: 36px;
    position: relative;
  }

  .previous {
    grid-column: 1;

    button:before {
      content: "‹";
      margin-right: ${v.baseline(0.5)};
      left: -5px;
      top: -1px;
      float: left;
    }
  }
  .next {
    grid-column: 2;
    text-align: right;

    button:after {
      content: "›";
      margin-left: ${v.baseline(0.5)};
      right: -5px;
      top: -1px;
      float: right;
    }
  }
`

const Button = styled(motion.button)`
  ${v.reset};
  ${v.text.a};
  -webkit-tap-highlight-color: transparent;
  background: ${v.purple};
  padding: ${v.baseline()} ${v.baseline(1.75)};
  border-radius: 100px;
  color: white;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  transition: color ${v.speed.fast}, border-color ${v.speed.fast},
    background-color ${v.speed.fast};

  &:hover {
    background-color: ${v.darkPurple};
  }

  ${props =>
    props.outline &&
    css`
      background: none;
      border: 2px solid ${v.purple};
      color: ${v.purple};
      padding: 10px ${v.baseline(1.5)};
      &:hover {
        background: none;
        border-color: ${v.darkPurple};
        color: ${v.darkPurple};
      }
    `}
`

const Step = ({ step }) => {
  console.log("Step", step)

  const instruction =
    step.step_image?.value?.instruction?.value ||
    step?.step_video?.value?.instruction?.value
  const image = step.step_image?.value?.illustration?.value?.url
  const video = step.step_video?.value?.illustration?.value

  return (
    <>
      <div>{instruction && <p>{instruction}</p>}</div>
      {image && <img src={image} />}
      {video && (
        <video playsInline autoPlay loop muted key={video}>
          <source src={video}></source>
        </video>
      )}
    </>
  )
}

const Guide = ({ data, pageContext }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const title = data.cockpitGuide.title?.value
  const subtitle = data.cockpitGuide.subtitle?.value
  const therapy = data.cockpitGuide.therapy?.value.name.value
  const patient = data.cockpitGuide.patient?.value.name.value
  const mode = data.cockpitGuide.mode?.value.name.value
  const steps = data.cockpitGuide.steps?.value.map(step => step.value)
  const crumb = data.cockpitGuide.crumb?.value

  const backlink = "/" + pageContext.breadcrumb[1].slug

  console.log("Breadcrumb", pageContext.breadcrumb, steps)

  return (
    <Layout>
      <SEO title={title + " for " + patient + " " + therapy} />
      <Container>
        {/* <Header
          title={mode}
          subtitle={title}
          breadcrumb={pageContext.breadcrumb}
          crumb={crumb}
        ></Header> */}
        <GuideHeader>
          <h1>
            <Link to={backlink}>
              <span className="back">‹</span> {mode}
            </Link>
            <span> {title}</span>
          </h1>
        </GuideHeader>
        <GuideSteps>
          <GuideStep number={currentStep + 1}>
            <Step step={steps[currentStep]} />
          </GuideStep>

          {/* {steps?.map((step, i) => (
            <>
              <GuideStep key={i + 1} id={i + 1}>
                <Step step={step} />
                {step.instruction && (
                  <div>
                    <p>{step.instruction.value}</p>
                  </div>
                )}
                {step.illustration && (
                  <img src={step.illustration.value.url}></img>
                )}
              </GuideStep>
              <NextPrevious>
                <li className="previous">Previous</li>
                <li className="next">Next</li>
              </NextPrevious>
            </>
          ))} */}
        </GuideSteps>
        <NextPrevious>
          {currentStep != 0 && (
            <li className="previous">
              <Button
                outline
                onClick={() => setCurrentStep(currentStep - 1)}
                whileTap={{ scale: 0.9 }}
              >
                Previous
              </Button>
            </li>
          )}
          {currentStep < steps.length - 1 && (
            <li className="next">
              <Button
                onClick={() => setCurrentStep(currentStep + 1)}
                whileTap={{ scale: 0.9, stiffness: 60 }}
              >
                Next
              </Button>
            </li>
          )}
        </NextPrevious>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($guideId: String) {
    cockpitGuide(cockpitId: { eq: $guideId }) {
      title {
        value
        slug
      }
      subtitle {
        value
      }
      crumb {
        value
      }
      steps {
        value {
          value {
            step_image {
              value {
                illustration {
                  value {
                    url
                  }
                }
                instruction {
                  value
                }
              }
            }
            step_video {
              value {
                illustration {
                  value
                }
                instruction {
                  value
                }
              }
            }
          }
        }
      }
      therapy {
        value {
          id
          name {
            value
            slug
          }
          crumb {
            value
          }
        }
      }
      mode {
        value {
          name {
            value
            slug
          }
        }
      }
      patient {
        value {
          id
          name {
            value
            slug
          }
          crumb {
            value
          }
        }
      }
    }
  }
`

export default Guide
